.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: var(--container-width);
  margin: 0 auto;
  background-image: url("/img/login-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.logo-img-container {
  min-width: 50%;
}

.login-button {
  color: #ffffff;
  background-color: #ff66af;
  padding: 16px 32px;
  border-radius: 40px;
  font-weight: bold;
  margin-top: 32px;
}

.login-text {
  word-break: keep-all;
  text-align: center;
  margin: 12px;
}

@media (max-width: 768px) {
  .login-text {
    padding: 0 60px;
  }
}